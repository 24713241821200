import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

import Layout from "../../components/layout";
import HeroWithImage from "../../components/heroWithImage/index";
import OurValues from "../../containers/about-us/OurValues";
import Description from "../../containers/about-us/Description";
import OurOperationals from "../../containers/about-us/OurOperational";
import { MarketingPosition } from "./../../containers/homepage";
import Seo from "../../components/seo";

import heroDesktop from "../../assets/heroDesktop/PurposeAndValues.png";
import heroMobile from "../../assets/heroMobile/PurposeAndValues.png";

const OurPurposeAndValue = ({ data }) => {
  const intl = useIntl();
  return (
    <Layout inverted>
      <Seo title="PURPOSE AND VALUES" />
      <HeroWithImage
        title={intl.locale === "fr" ? "OBJECTIF ET VALEUR" : <>PURPOSE &&nbsp;&nbsp;VALUES</>}
        heroMobile={heroMobile}
        heroDesktop={heroDesktop}
      />
      <MarketingPosition data={data.marketposition.nodes[0]} white className="bg-white py-10 lg:py-18.5" />
      <Description data={data.heading.nodes[0]} />
      <OurValues data={data.ourvalues.nodes[0]} />
      <OurOperationals data={data.operationalstandards.nodes[0]} />
    </Layout>
  );
};

export const query = graphql`
  query OurPurposeAndValues($locale: String) {
    heading: allContentfulOurPurposeAndValuesPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        heading
      }
    }

    marketposition: allContentfulMarketPositionOurPurposeAndValues(filter: { node_locale: { eq: $locale } }) {
      nodes {
        title
        subtitle
        content {
          raw
        }
        features {
          items {
            title
            content
          }
        }
      }
    }

    ourvalues: allContentfulOurPurposeAndValuesPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        valueTitle
        valueContent {
          raw
        }
        valueShortDescription
        values {
          items {
            title
          }
        }
      }
    }

    operationalstandards: allContentfulOperationalStandardsOurPurposeAndValues(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        title
        content {
          raw
        }
      }
    }
  }
`;

export default OurPurposeAndValue;
